/* eslint-disable */
gsap.registerPlugin(ScrollTrigger);

let scroll;

const body = document.body;
const select = (e) => document.querySelector(e);
const selectAll = (e) => document.querySelectorAll(e);
// const container = select('.site-main');

initPageTransitions();

const circleTopHeight = "100vw";
const circleBottomHeight = "100vw";
const circleTopHeightSmall = "100vw";
const circleBottomHeightSmall = "100vw";

// Animation - First Page Load
function initLoader() {
  console.log("initLoader");

  var tl = gsap.timeline();

  // Set all elements for transition
  var transitionColors = [ "#056CF2", "#05F2F2", "#F2308B", "#F2CB05" ];
  var randomColor = transitionColors[Math.floor(Math.random()*transitionColors.length)];

  tl.set("html", {
    cursor: "wait"
  });

  tl.set(".transition-inner", {
    top: "0%",
  });

  tl.set(".transition-inner", {
    backgroundColor: randomColor
  });

  tl.set(".half-circle, .transition-logo", {
    backgroundColor: randomColor
  });

  // Animate logo
  tl.to(".transition-logo", {
    duration: .8,
    opacity: 1,
    y: -50,
    ease: "Power4.easeOut",
    delay: .05
  });

  // Move out
  tl.to(".transition-inner", {
    duration: .8,
    top: "-225%",
    ease: "Power4.easeInOut",
    delay: .5
  }, "=-0.8");

  tl.set("html", {
    cursor: "auto"
  },"=-1.2");

  tl.call(function() {
    scroll.start();
  });
}

// Animation - Page transition In
function pageTransitionIn() {
  var tl = gsap.timeline();

  $(".cursor").removeClass("project");
  $(".cursor-follower").removeClass("project");

  // Reset GIF
  var logoAnimation = $(".transition-logo img").attr('src');
  $(".transition-logo img").attr('src', " ");
  $(".transition-logo img").attr('src', logoAnimation);

  tl.call(function() {
    scroll.stop();
  });

  var transitionColors = [ "#056CF2", "#05F2F2", "#F2308B", "#F2CB05" ];
  var randomColor = transitionColors[Math.floor(Math.random()*transitionColors.length)];

  tl.set(".transition-inner", {
    backgroundColor: randomColor
  });

  tl.set(".half-circle, .transition-logo", {
    backgroundColor: randomColor
  });

  tl.set("html", {
    cursor: "wait"
  });

  tl.set(".transition-inner", {
    top: "225%",
  });

  tl.to(".transition-inner", {
    duration: .8,
    top: "0%",
    ease: "Power4.easeInOut",
  });

  tl.to(".transition-inner", {
    duration: .8,
    top: "-225%",
    ease: "Power4.easeInOut",
    delay: .5
  });

  tl.to(".transition-logo", {
    duration: .8,
    opacity: 1,
    y: -50,
    ease: "Power4.easeOut",
    delay: .05
  }, "=-.8");
}


// Animation - Page transition Out
function pageTransitionOut() {
  var tl = gsap.timeline();

  if ($(window).width() > 540) {
    tl.set(".inner-main .once-in", {
      y: "50vh",
    });
  } else {
    tl.set(".inner-main .once-in", {
      y: "20vh"
    });
  }

  tl.call(function() {
    scroll.start();
  });

  tl.to(".inner-main .once-in", {
    duration: 1,
    y: "0vh",
    stagger: .05,
    ease: "Expo.easeOut",
    delay: .8,
    clearProps: "true"
  });
}

function initPageTransitions() {
  // do something before the transition starts
  barba.hooks.before(() => {
    select('html').classList.add('is-transitioning');
  });

  // do something after the transition finishes
  barba.hooks.after(() => {
    select('html').classList.remove('is-transitioning');
    // reinit locomotive scroll
    scroll.init();
    scroll.stop();
  });

  // scroll to the top of the page
  barba.hooks.enter(() => {
    scroll.destroy();
  });

  // scroll to the top of the page
  barba.hooks.afterEnter(() => {
    window.scrollTo(0, 0);
    initCookieViews();
    var vids = document.querySelectorAll("video"); vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });
  });

  if ($(window).width() > 540) {
    barba.hooks.leave(() => {
      $(".btn-hamburger, .btn-menu").removeClass('active');
      $("main").removeClass('nav-active');
    });
  }

  barba.init({
    sync: true,
    debug: false,
    timeout: 7000,
    prevent: ({ el }) => el.classList && el.classList.contains('prevent'),
    transitions: [{
      name: 'default',
      once(data) {
        // do something once on the initial page load
        initSmoothScroll(data.next.container);
        initScript();
        initCookieViews();
        initLoader();
      },
      async leave(data) {
        // animate loading screen in
        pageTransitionIn(data.current);
        await delay(495);
        data.current.container.remove();
      },
      async enter(data) {
        // animate loading screen away
        pageTransitionOut(data.next);
        // initNextWord(data);
      },
      async beforeEnter(data) {
        ScrollTrigger.getAll().forEach(t => t.kill());
        scroll.destroy();
        initSmoothScroll(data.next.container);
        initScript();
      },
    }]
  });

  function initSmoothScroll(container) {
    // console.log("New scroll");
    scroll = new LocomotiveScroll({
      el: container.querySelector('[data-scroll-container]'),
      smooth: true,
    });

    window.onresize = scroll.update();

    scroll.on("scroll", () => ScrollTrigger.update());

    ScrollTrigger.scrollerProxy('[data-scroll-container]', {
      scrollTop(value) {
        return arguments.length ? scroll.scrollTo(value, 0, 0) : scroll.scroll.instance.scroll.y;
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: container.querySelector('[data-scroll-container]').style.transform ? "transform" : "fixed"
    });

    ScrollTrigger.defaults({
      scroller: document.querySelector('[data-scroll-container]'),
    });

    /**
    * Remove Old Locomotive Scrollbar
    */

    const scrollbar = selectAll('.c-scrollbar');

    if(scrollbar.length > 1) {
      // console.log("remove bar");
      scrollbar[0].remove();
    }

    // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
    ScrollTrigger.addEventListener('refresh', () => scroll.update());

    // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    ScrollTrigger.refresh();
  }
}

function delay(n) {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
}


/**
* Fire all scripts on page load
*/
function initScript() {
  select('body').classList.remove('is-loading');
  initWindowInnerheight();
  initCheckTouchDevice();
  initHamburgerNav();
  initMagneticButtons();
  initSliderController();
  initStickyCursorWithDelay();
  // initScrolltriggerNav();
  initSplitWords();
  initPlayVideo();

  function scrollAnimations() {
    initScrolltriggerAnimations();
  }
  setTimeout(scrollAnimations, 700);
}

/**
* Window Inner Height Check
*/
function initWindowInnerheight() {

  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  $(document).ready(function(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    $('.btn-hamburger').click(function(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  });

}

/**
* Check touch device
*/
function initCheckTouchDevice() {

  function isTouchScreendevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
  };

  if(isTouchScreendevice()){
    $('inner-main').addClass('touch');
    $('inner-main').removeClass('no-touch');
  } else {
    $('inner-main').removeClass('touch');
    $('inner-main').addClass('no-touch');
  }
  $(window).resize(function() {
    if(isTouchScreendevice()){
      $('inner-main').addClass('touch');
      $('inner-main').removeClass('no-touch');
    } else {
      $('inner-main').removeClass('touch');
      $('inner-main').addClass('no-touch');
    }
  });

}

/**
* Hamburger Nav Open/Close
*/
function initHamburgerNav() {

  // Open/close navigation when clicked .btn-hamburger

  $(document).ready(function(){
    $(".mobile-nav").click(function(){
      if ($(this).hasClass('active')) {
        $(".nav-items, .mobile-nav").removeClass('active');
        $("main").removeClass('nav-active');
        scroll.start();
      } else {
        $(".nav-items, .mobile-nav").addClass('active');
        $("main").addClass('nav-active');
        scroll.stop();
      }
    });

  });
  $(document).keydown(function(e){
    if(e.keyCode == 27) {
      if ($('main').hasClass('nav-active')) {
        $(".btn-hamburger, .btn-menu").removeClass('active');
        $("main").removeClass('nav-active');
        scroll.start();
      }
    }
  });
}

/**
* Slider controller
*/
function initSliderController() {
  $(document).ready(function(){
    // Next
    $('.project-slider-controller.next').click(function(){
      var active = $(".slider-item.active").removeClass('active');
      $('.project-slider-controller.prev').removeClass('inactive');

      if(active.next() && active.next().length){
        active .next().addClass('active');

        var position = $(".slider-item.active").position();
        var left = position.left;
        var sliderItemWidth = $(".slider-item.active").width();
        var sliderInnerWidth = $(".project-slider-inner").width();
        var offsetForLastElement = sliderInnerWidth - sliderItemWidth - ( $( window ).width() - sliderItemWidth );

        if($(".slider-item.active").is(':last-child')){
          $('.project-slider-controller.next').addClass('inactive');
          gsap.to( ".project-slider-inner", 0.9, {
            x: -offsetForLastElement,
            y: 0,
            ease: Power2.easeOut
          });
        }
        else {
          gsap.to( ".project-slider-inner", 0.9, {
            x: -left,
            y: 0,
            ease: Power2.easeOut
          });
        }
      }
    });

    // Previous
    $('.project-slider-controller.prev').click(function(){
      var active = $(".slider-item.active").removeClass('active');
      $('.project-slider-controller.next').removeClass('inactive');

      if(active.prev() && active.prev().length){
        active .prev().addClass('active');

        var position = $(".slider-item.active").position();
        var left = position.left;
        var sliderItemWidth = $(".slider-item.active").width();
        var sliderInnerWidth = $(".project-slider-inner").width();
        var offsetForLastElement = sliderInnerWidth - sliderItemWidth - ( $( window ).width() - sliderItemWidth );

        if($(".slider-item.active").is(':first-child')){
          $('.project-slider-controller.prev').addClass('inactive');
          gsap.to( ".project-slider-inner", 0.9, {
            x: 0,
            y: 0,
            ease: Power2.easeOut
          });
        }
        else {
          gsap.to( ".project-slider-inner", 0.9, {
            x: -left,
            y: 0,
            ease: Power2.easeOut
          });
        }
      }
    });

    $('.carousel-action.next').click(function(){
      if( $('.carousel').attr('data-scroll') == '3' ) {
        $('.carousel').attr('data-scroll', 1);
      }
      else {
        var carouselCount = parseInt($('.carousel').attr("data-scroll"));
        var carouselSet = carouselCount + 1;
        $('.carousel').attr('data-scroll', carouselSet);
      }
      animateCarousel()
    });

    $('.carousel-action.prev').click(function(){
      if( $('.carousel').attr('data-scroll') == '1' ) {
        $('.carousel').attr('data-scroll', 3);
      }
      else {
        var carouselCount = parseInt($('.carousel').attr("data-scroll"));
        var carouselSet = carouselCount - 1;
        $('.carousel').attr('data-scroll', carouselSet);
      }
      animateCarousel()
    });

    function animateCarousel() {
      var carouselCount = parseInt($('.carousel').attr("data-scroll"));
      var carouselTransform = String( (carouselCount-1) * -100 + "%");

      gsap.to( ".carousel .carousel-item", 0.9, {
        x: carouselTransform,
        ease: Power2.easeOut
      });
    }

  });
}

/**
* Magnetic Buttons
*/
function initMagneticButtons() {
  // Magnetic Buttons
  // Found via: https://codepen.io/tdesero/pen/RmoxQg
  var magnets = document.querySelectorAll('.magnetic');
  var strength = 100;

  // START : If screen is bigger as 540 px do magnetic
  if(window.innerWidth > 540){
    // Mouse Reset
    magnets.forEach( (magnet) => {
      magnet.addEventListener('mousemove', moveMagnet );
      $(magnet.parentNode).removeClass('not-active');
      magnet.addEventListener('mouseleave', function(event) {
        gsap.to( event.currentTarget, 1.5, {
          x: 0,
          y: 0,
          ease: Elastic.easeOut
        });
        gsap.to( $(this).find(".btn-fill"), 1.5, {
          x: 0,
          y: 0,
          rotate: "0.001deg",
          ease: Elastic.easeOut
        });
        gsap.to( $(this).find(".btn-text"), 1.5, {
          x: 0,
          y: 0,
          ease: Elastic.easeOut
        });
      });
    });

    // Mouse move
    function moveMagnet(event) {
      var magnetButton = event.currentTarget;
      var bounding = magnetButton.getBoundingClientRect();
      var magnetsStrength = magnetButton.getAttribute("data-strength");
      var magnetsStrengthText = magnetButton.getAttribute("data-strength-text");

      gsap.to( magnetButton, 1.5, {
        x: ((( event.clientX - bounding.left)/magnetButton.offsetWidth) - 0.5) * magnetsStrength,
        y: ((( event.clientY - bounding.top)/magnetButton.offsetHeight) - 0.5) * magnetsStrength,
        rotate: "0.001deg",
        ease: Power4.easeOut
      });
      gsap.to( $(this).find(".btn-fill"), 1.5, {
        x: ((( event.clientX - bounding.left)/magnetButton.offsetWidth) - 0.5) * magnetsStrengthText,
        y: ((( event.clientY - bounding.top)/magnetButton.offsetHeight) - 0.5) * magnetsStrengthText,
        rotate: "22.5deg",
        ease: Power4.easeOut
      });
      gsap.to( $(this).find(".btn-text"), 1.5, {
        x: ((( event.clientX - bounding.left)/magnetButton.offsetWidth) - 0.5) * magnetsStrengthText,
        y: ((( event.clientY - bounding.top)/magnetButton.offsetHeight) - 0.5) * magnetsStrengthText,
        rotate: "0.001deg",
        ease: Power4.easeOut
      });
    }

  }; // END : If screen is bigger as 540 px do magnetic

  // Mouse Enter
  $('.btn-click.magnetic').on('mouseenter', function() {
    $(this.parentNode).removeClass('not-active');
  });

  // Mouse Leave
  $('.btn-click.magnetic').on('mouseleave', function() {
    $(this.parentNode).removeClass('not-active');
  });
}
/**
* Sticky Cursor with Delay
*/
function initStickyCursorWithDelay() {
  // Sticky Cursor with delay
  var cursor = $(".cursor"),
  follower = $(".cursor-follower");

  if (!cursor.hasClass("moved")) {
    var posX = 0,
    posY = 0;

    var mouseX = 0,
    mouseY = 0;
  }

  // Cursor follow
  $(document).on("mousemove", function(e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
    cursor.addClass("moved");
    follower.addClass("moved");
  });

  const followerAnimation = gsap.timeline();

  // Cursor if leaves window
  document.addEventListener("mouseleave", (event) => {
    if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
      cursor.addClass("out");
      follower.addClass("out");
    }
  });
  document.addEventListener("mouseenter", (event) => {
    cursor.removeClass("out");
    follower.removeClass("out");
  });


  TweenMax.to({}, 0.016, {
    repeat: -1,
    onRepeat: function() {
      posX += (mouseX - posX) / 9;
      posY += (mouseY - posY) / 9;

      TweenMax.set(follower, {
        css: {
          left: posX - 12,
          top: posY - 12
        }
      });

      TweenMax.set(cursor, {
        css: {
          left: mouseX,
          top: mouseY
        }
      });
    }
  });

  // add animation on certain links
  $(".link").on("mouseenter", function() {
    cursor.addClass("active");
    follower.addClass("active");
  });
  $(".link").on("mouseleave", function() {
    cursor.removeClass("active");
    follower.removeClass("active");
  });

  // add animation on projects
  $(".project-link, .service.link").on("mouseenter", function() {
    cursor.addClass("project");
    follower.addClass("project");
  });
  $(".project-link, .service.link").on("mouseleave", function() {
    cursor.removeClass("project");
    follower.removeClass("project");
  });

  // add animation on projects
  $(".service").on("mouseenter", function() {
    // cursor.addClass("service");
    // follower.addClass("service");
    var title = $(this).find(".service-title");
    var span = $(this).find(".service-title span");
    var titleWidth = title.width() - 60;
    var spanWidth = span.width();
    var ratio = titleWidth / spanWidth;

    gsap.to(span, {
      scaleX: ratio,
      duration: 1,
      ease: Elastic.easeOut.config(1, 0.30)
    });

    gsap.to("#radial-start-color", {
      stopColor: "rgba(242, 203, 5, .7)",
      duration: 1,
      ease: Power3.easeOut
    });
    gsap.to("#radial-end-color", {
      stopColor: "rgba(242, 203, 5, 0)",
      duration: 1,
      ease: Power3.easeOut
    });

  });

  $(".service").on("mouseleave", function() {
    gsap.to(".service .service-title span", {
      scaleX: 1,
      duration: 1,
      ease: Elastic.easeOut.config(1, 0.30)
    });
    gsap.to("#radial-start-color", {
      stopColor: "rgba(242, 48, 139, .5)",
      duration: 1,
      ease: Power3.easeOut
    });
    gsap.to("#radial-end-color", {
      stopColor: "rgba(242, 48, 139, 0)",
      duration: 1,
      ease: Power3.easeOut
    });
  });
}

/**
* Cookie Views
*/
function initCookieViews() {
  // Set cookie for columns/rows view
  // https://www.youtube.com/watch?v=rfwiyBoVwdQ&ab_channel=TimothyRicks
  // if (Cookies.get("view") == "columns") {
  //   $('.grid-row .rows-btn').removeClass('active');
  //   $('.grid-row .columns-btn').addClass('active');
  //   $('#work .grid-rows-part').removeClass('visible');
  //   $('#work .grid-columns-part').addClass('visible');
  //   scroll.update();
  //   ScrollTrigger.refresh();
  // }
}

/**
* Contact Form
*/
function initContactForm() {

  $('.field').on('input', function() {
    $(this).parent().toggleClass('not-empty', this.value.trim().length > 0);
  });

  $(function () {
    $('.field').focusout(function () {
      var text_val = $(this).val();
      $(this).parent().toggleClass('not-empty', text_val !== "");
    }).focusout();
  });

}

/**
* Lazy Load
*/
function initLazyLoad() {
  // https://github.com/locomotivemtl/locomotive-scroll/issues/225
  // https://github.com/verlok/vanilla-lazyload
  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
  });
}

/**
* Play Videos
*/
function initPlayVideo() {
  console.log("initplay vid");

  // $( ".video-conroller" ).on( "click", function() {
  //   var currentController = $( this );
  //   var videoID = $( this ).attr("data-id");
  //   var videoPlayer = videojs(videoID);

  //   if (currentController.hasClass("playing")) {
  //     console.log( "stop" );
  //     videoPlayer.pause();
  //     currentController.toggleClass("playing");
  //     currentController.text("Play");
  //   }
  //   else {
  //     console.log( "Go" );
  //     currentController.toggleClass("playing");
  //     currentController.text("Pause");

  //     videojs(videoID).ready(function(){
  //       videoPlayer.play();

  //       this.on("ended", function(){
  //         console.log( "Ended" );
  //         currentController.toggleClass("playing");
  //         currentController.text("Play");
  //         videoPlayer.pause();
  //       });
  //     });
  //   }
  // });
}

/**
* Scrolltrigger Animations Desktop + Mobile
*/

function initSplitWords() {

  // Copyright start
  // Â© Code by T.RICKS, https://www.tricksdesign.com/
  // You have the license to use this code in your projects but not redistribute it to others
  // Tutorial: https://www.youtube.com/watch?v=xiAqTu4l3-g&ab_channel=TimothyRicks

  // Find all text with .tricks class and break each letter into a span
  if(document.querySelector(".split-lines")) {
    var spanWord = document.getElementsByClassName("split-lines");
    for (var i = 0; i < spanWord.length; i++) {
      var wordWrap = spanWord.item(i);
      wordWrap.innerHTML = wordWrap.innerHTML.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1<span class="split-line"><span class="split-line-inner">$2</span></span>');
    }
  }
}

function initScrolltriggerAnimations() {
  scroll.update();
  if(document.querySelector(".footer-wrap")) {
    // Scrolltrigger Animation : Footer + hamburger
    $(".footer-wrap").each(function (index) {
      let triggerElement = $(this);
      let targetElementHamburger = $(".btn-hamburger .btn-click");

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: triggerElement,
          start: "50% 100%",
          end: "100% 120%",
          scrub: 0
        }
      });
      tl.from(targetElementHamburger, {
        boxShadow: "0px 0px 0px 0px rgb(0, 0, 0)",
        ease: "none"
      });
    });
  }

  if(document.querySelector(".ticker")) {
    gsap.to('.ticker h2', {
      xPercent: -50,
      ease: "power3.out",
      duration: 1,
      scrollTrigger: {
        trigger: ".inner-main",
        start: "top center",
        end: "bottom top",
        scrub: true
      }
    })
  }

  // Text lines in
  if(document.querySelector(".split-lines.animate")) {
    gsap.utils.toArray('.split-lines.animate').forEach(section => {

      // split the lines
      const line = section.querySelectorAll('.split-line-inner');

      gsap.from(line, {
        y: "100%",
        stagger: .01,
        ease: "power3.out",
        duration: 1,
        delay: 0.4,
        scrollTrigger: {
          trigger: section,
          once: true,
          start: "0% 100%",
          end: "100% 0%"
        }
      });
    });
  }

  // Type effect for type font
  if(document.querySelectorAll("p strong")) {
    gsap.utils.toArray('p strong').forEach(section => {
      // get the image
      // var typeText = $( section ).text();
      // $( section ).text( " " );
      gsap.from(section, {
        x: "-20%",
        duration: 1.4,
        delay: .8,
        ease: "power3.out",
        stagger: 0.4,
        scrollTrigger: {
          trigger: section,
          once: true,
          start: "0% 100%",
          end: "100% 0%"
        }
      })
    });
  }

  if(document.querySelectorAll(".fade-in.animate")) {
    gsap.utils.toArray('.fade-in.animate').forEach(section => {

      gsap.from(section, {
        y: "2em",
        opacity: 0,
        ease: "expo.out",
        duration: 1.8,
        delay: .6,
        scrollTrigger: {
          trigger: section,
          once: true,
          start: "0% 100%",
          end: "100% 0%"
        }
      })
    });
  }

  if(document.querySelector(".client-logo-container")) {
    gsap.utils.toArray('.client-logo-container').forEach(section => {

      // get the image
      const clientLogo = section.querySelectorAll('.client-logo');

      // create tween for the image
      gsap.from(clientLogo, {
        y: "20%",
        opacity: "0",
        stagger: .08,
        ease: "power3.out",
        duration: 1,
        delay: 0.1,
        scrollTrigger: {
          trigger: section,
          toggleActions:'play none none reset',
          start: "0% 100%",
          end: "100% 0%"
        }
      });
    });
  }

  if(document.querySelector("footer")) {
    gsap.utils.toArray('footer').forEach(section => {

      // get the image
      const footerColumn = section.querySelectorAll('.column');

      // create tween for the image
      gsap.from(footerColumn, {
        y: "20%",
        opacity: "0",
        stagger: .08,
        ease: "power3.out",
        duration: 1,
        delay: 0.3,
        scrollTrigger: {
          trigger: section,
          toggleActions:'play none none reset',
          start: "0% 100%",
          end: "100% 0%"
        }
      });
    });
  }

  // Disable GSAP on Mobile
  // Source: https://greensock.com/forums/topic/26325-disabling-scrolltrigger-on-mobile-with-mediamatch/
  ScrollTrigger.matchMedia({

    // Desktop Only Scrolltrigger
    "(min-width: 1000px)": function() {

      /**
      * Scrolltrigger Scroll Check
      */
      ScrollTrigger.create({
        start: 'top -10%',
        onUpdate: self => {
          // Adjust padding on scroll
          $(".inner-main").addClass('scrolled');
          gsap.to(".header nav .o-container", {
            y:"-20px",
            duration: 0.6,
            ease: Power3.easeOut
          });
          // Hide opening hours on scroll
          gsap.to(".opening-hours", {
            opacity: 0,
            duration: 0.6,
            ease: Power3.easeOut
          });
          gsap.fromTo(".nav-items", {
            backgroundColor: "rgba(255,255,255,0)",
          }, {
            backgroundColor: "rgba(255,255,255,1)",
            paddingRight: "12px",
            duration: 0.6,
            ease: Power3.easeOut
          });
        },
        onLeaveBack: () => {
          $(".inner-main").removeClass('scrolled');
          gsap.to(".header nav .o-container", {
            y: "0px",
            duration: 0.6,
            ease: Power3.easeOut
          });
          gsap.to(".nav-items", {
            backgroundColor: "rgba(255,255,255,0)",
            paddingRight: "0px",
            duration: 0.6,
            ease: Power3.easeOut
          });
          // Hide opening hours on scroll
          gsap.to(".opening-hours", {
            opacity: 1,
            height: "60px",
            duration: 0.6,
            ease: Power3.easeOut
          });
        },
      });


    }, // End Desktop Only Scrolltrigger

    // Mobile Only Scrolltrigger
    "(max-width: 999px)": function() {


    } // End Mobile Only Scrolltrigger

  }); // End GSAP Matchmedia
}

